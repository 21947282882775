import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import BarraInferior from "components/BarraInferior/BarraInferior";
import BarraCopyright from "components/BarraCopyright/BarraCopyright";
import logoFooter from "assets/img/logo_footer.png";
import backgroundFooter from "assets/img/fondo.png";
import DelegacionFooter from "./DelegacionFooter";

const useStyles = makeStyles({
    principal: {
      width: "100%",
      fontFamily: "Lato",
      fontWeight: 700,
    },
    footer: {
      width: "100%",
      backgroundImage: "url(" + backgroundFooter + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "400px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      padding: "20px 0", // Agrega un poco de padding adicional para que el contenido no esté tan ajustado
      paddingTop: "40px", // Ajusta el padding superior para que no esté tan pegado al borde
        ["@media (max-width: 768px)"]: {
            paddingTop: "20px", // Ajusta el padding superior en móviles
        },

        paddingBottom: "40px", // Ajusta el padding inferior para que no esté tan pegado al borde

        ["@media (max-width: 768px)"]: {
            paddingBottom: "20px", // Ajusta el padding inferior en móviles
        },
    },
    footerContainer: {
      height: "100%",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 20px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1.2fr 1.2fr 1.2fr 1fr 1fr 1fr 1fr",
      gridTemplateRows: "1fr",
      gridTemplateAreas: "'logo logo info info info list1 list1 list2 list2'",
      color: "#ffffff",
      ["@media (max-width: 1200px)"]: {
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "2fr 2fr 2fr 1fr",
        gridTemplateAreas: `
                            "logo list1 list2"
                            "logo list1 list2"
                            "logo list1 list2"
                            "info info info"`,
      },
      ["@media (max-width: 768px)"]: {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 0.7fr 1fr",
        gridTemplateAreas: `
                            "logo"
                            "logo"
                            "list1"
                            "list1"
                            "list2"
                            "list2"
                            "info"`,
        padding: "10px", // Ajusta el padding en pantallas más pequeñas
      },
    },
    logo: {
      gridArea: "logo",
      position: "relative",
    //   "&::before": {
    //     position: "absolute",
    //     height: "75px",
    //     display: "block",
    //     width: "0px",
    //     borderRight: "3px solid #f9d03f",
    //     content: "''",
    //     transform: "translate(50%, -50%)",
    //     top: "50%",
    //     right: 0,
    //     ["@media (max-width: 768px)"]: {
    //       width: "75px",
    //       display: "block",
    //       height: "0px",
    //       borderBottom: "3px solid #f9d03f",
    //       top: "100%",
    //       right: "50%",
    //     },
    //   },
      ["@media (max-width: 1200px)"]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
      },
      ["@media (max-width: 768px)"]: {
        alignItems: "center",
      },
    },
    imgLogo: {
      width: "100%",
      maxWidth: "210px",
      height: "auto",
      ["@media (max-width: 768px)"]: {
        maxWidth: "150px", // Ajusta el tamaño en móviles
      },
    },
    info: {
      gridArea: "info",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 2rem",
      position: "relative",
      flexDirection: "column", // Para que el texto y el iframe estén en columna
    //   "&::before": {
    //     position: "absolute",
    //     height: "75px",
    //     display: "block",
    //     width: "0px",
    //     borderRight: "3px solid #f9d03f",
    //     content: "''",
    //     transform: "translate(50%, -50%)",
    //     top: "50%",
    //     right: 0,
    //   },
      ["@media (max-width: 1200px)"]: {
        "&::before": {
          content: "none",
        },
      },
    },
    infoText: {
      margin: 0,
      textAlign: "left",
      fontSize: "1rem",
      ["@media (max-width: 1200px)"]: {
        textAlign: "center",
      },
      ["@media (max-width: 768px)"]: {
        fontSize: "0.9rem", // Ajusta el tamaño de fuente en móviles
      },
    },
    iframeContainer: {
      marginTop: "1rem", // Espacio entre el texto y el iframe
      width: "100%", // Asegura que ocupe el ancho completo
      maxWidth: "600px", // Ajusta el tamaño máximo del iframe
      marginBottom: "1rem", // Agrega margen inferior para más espacio en móviles
    },
    iframe: {
      width: "100%", // Ajusta el iframe al 100% del contenedor
      border: "none", // Remueve el borde
      ["@media (max-width: 768px)"]: {
        height: "300px", // Ajusta la altura en móviles
      },
    },
    list1: {
      gridArea: "list1",
      position: "relative",
      listStyle: "none",
      margin: 0,
      ["@media (max-width: 768px)"]: {
        padding: "2rem 0",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      },
    //   "&::before": {
    //     position: "absolute",
    //     height: "75px",
    //     display: "block",
    //     width: "0px",
    //     borderRight: "3px solid #f9d03f",
    //     content: "''",
    //     transform: "translate(50%, -50%)",
    //     top: "50%",
    //     right: 0,
    //     ["@media (max-width: 768px)"]: {
    //       width: "75px",
    //       display: "block",
    //       height: "0px",
    //       borderBottom: "3px solid #f9d03f",
    //       top: "100%",
    //       right: "50%",
    //     },
    //   },
    },
    list2: {
      gridArea: "list2",
      position: "relative",
      listStyle: "none",
      margin: 0,
      ["@media (max-width: 768px)"]: {
        padding: "2rem 0",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      },
    },
    item: {
      margin: "1rem 0",
      ["@media (max-width: 768px)"]: {
        margin: "0.5rem 0", // Reduce el margen en móviles para mejorar el espacio
      },
    },
    link: {
      color: "#ffffff",
      "&:hover": {
        color: "#f9d03f",
      },
    },
    ContainerDelegacion: {
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: "1rem",
    },
    subencionaText: {
      fontSize: "1rem",
      color: "#3c4858",
    },
  });


export default function Footer({ isLogin, userName }) {
  const {
    footer,
    footerContainer,
    logo,
    imgLogo,
    info,
    infoText,
    iframeContainer,
    iframe,
    list1,
    list2,
    item,
    link,
    principal,
  } = useStyles();

  // UseEffect para agregar el script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.ipzmarketing.com/assets/signup_form/iframe_v1.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <footer className={principal}>
        <BarraInferior color="white" backgroundColor="#289397" altura="40px" />
        <section className={footer}>
          <div className={footerContainer}>
            <picture className={logo}>
              <img
                className={imgLogo}
                src={logoFooter}
                alt="logo Córdoba de compras"
              />
            </picture>
            <div className={info}>
              <p className={infoText}>
                ¿Tienes alguna duda? Llama al{" "}
                <a style={{ color: "#f9d03f" }} href="tel:+34123456789">
                  957 472 524
                </a>{" "}
                o escribe a nuestro{" "}
                <a
                  style={{ color: "#f9d03f" }}
                  href="mailto:marketplace@comercioendigital.es"
                >
                  marketplace@comercioendigital.es
                </a>
              </p>

              {/* Aquí se renderiza el iframe debajo del texto */}
              <div className={iframeContainer}>
              <iframe data-skip-lazy=""
              style={{backgroundColor:"transparent !important"}}
              src="https://comercioendigital.ipzmarketing.com/f/zmXpOGEKEXQ" frameborder="0" scrolling="no" width="100%" class="ipz-iframe"></iframe> <script data-cfasync="false" type="text/javascript" src="https://assets.ipzmarketing.com/assets/signup_form/iframe_v1.js"></script>


              </div>
            </div>
            <ul className={list1}>
              <li className={item}>
                <Link to="/categorias" className={link}>
                  Categorías de productos
                </Link>
              </li>
              <li className={item}>
                <Link to="/establecimientos" className={link}>
                  ¡Vamos de tiendas!
                </Link>
              </li>
              <li className={item}>
                <Link to="/faq" className={link}>
                  ¿Cómo funciona?
                </Link>
              </li>
              {!isLogin && (
                <li className={item}>
                  <Link
                    to="/administrador"
                    className={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acceso Comercios
                  </Link>
                </li>
              )}
              {isLogin ? (
                <Link to="/perfil" className={link}>
                  Perfil {userName}!
                </Link>
              ) : (
                <li className={item}>
                  <Link to="/login" className={link}>
                    Login / registro
                  </Link>
                </li>
              )}
            </ul>
            <ul className={list2}>
              <li className={item}>
                <Link to="/avisolegal" className={link}>
                  Aviso legal
                </Link>
              </li>
              <li className={item}>
                <Link to="/privacidad" className={link}>
                  Política de privacidad
                </Link>
              </li>
              <li className={item}>
                <Link to="/cookies" className={link}>
                  Política de cookies
                </Link>
              </li>
              <li className={item}>
                <Link to="/pagoseguro" className={link}>
                  Pago seguro
                </Link>
              </li>
              <li className={item}>
                <Link to="/condiciones-de-uso" className={link}>
                  Condiciones De Uso
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <DelegacionFooter />
        <BarraCopyright color="black" backgroundColor="#f9d03f" altura="40px" />
      </footer>
    </>
  );
}
